/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

// redux, helpers
import SearchIcon from '../../BrandTheme/Icons/SearchIcon';
import ElasticSearchSuggestions from './ElasticSearchSuggestions/ElasticSearchSuggestions';
import useExperimentServiceAttributes from '../../../helpers/experimentService/useExperimentServiceAttributes';
import { clearSearchResult, getSearchResult } from '../../../../state/ducks/App/App-Actions';
import { trackEvent } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Actions';
import { emitCustomTrackingEvent } from '../../../../state/ducks/TagManager/ducks/ClickStreamEvents/ClickStreamEvent-Actions';
import { getCurrentPageType } from '../../../../state/ducks/TagManager/ducks/TagManager/TagManager-Selectors';

const useStyles = makeStyles((theme) => ({
    root: {
        flex: 1,
        maxWidth: 727,
        padding: '8px',
        margin: '0px 0px 0px 40px',
        width: '100%',
        position: 'relative',
        zIndex: '25',
        [theme.breakpoints.down(1026)]: {
            margin: 0,
            maxWidth: '100%',
            paddingTop: '4px',
        },
    },
    rootShadow: {
        boxShadow: '0px 10px 2px 0px #00000020',
    },
    paperRoot: {
        padding: '2px 8px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        border: '1px solid #C4C4C4',
        position: 'relative',
    },
    customPlaceHolder: {
        position: 'absolute',
        top: '50%',
        left: '38px',
        transform: 'translateY(-50%)',
        color: '#C4C4C4',
        pointerEvents: 'none',
        fontSize: '16px',
        '& sup': {
            fontSize: '10px',
        },
    },
    inputBase: {
        fontSize: '16px !important',
        flex: 1,
        color: '#000000',
        marginLeft: 8,
    },
    clearSearchButton: {
        margin: 0,
    },
    clearSearchIcon: {
        color: theme.palette.white,
        background: '#9D9D9D',
        borderRadius: '50%',
        height: '16px',
        width: '16px',
    },
}));

function SearchV2({
    typeSearchBox, brand, hideHeaderOnScroll, ffIsHideMobileHeaderOnScrollEnabled,
    salesforceResponse, searchButtonClick,
}) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const pageType = useSelector(getCurrentPageType);

    const searchContainerRef = useRef(null);
    const searchInputRef = useRef(null);

    const [inputFocus, setInputFocus] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [placeHolder, setPlaceHolder] = useState('Search');
    const [includeSiblingBrandSearch, setIncludeSiblingBrandSearch] = useState(true);

    const { targeting, isGraphqlTargetingEnabled, experimentService } = useExperimentServiceAttributes({ queryName: 'autoSuggest' });

    useEffect(() => {
        if (inputFocus) {
            if (includeSiblingBrandSearch) {
                setPlaceHolder(<>Search All Celebrations<sup>&reg;</sup> Brands</>);
            } else {
                setPlaceHolder(`Search ${brand?.['display-name']}`);
            }
        } else {
            setPlaceHolder('Search');
        }
    }, [inputFocus, includeSiblingBrandSearch, brand]);

    const handleClickOutside = (event) => {
        // Check if the click is outside the ref element
        if (searchContainerRef.current && !searchContainerRef.current.contains(event.target)) {
            setInputFocus(false);
        }
    };
    const lastScrollY = useRef(0);

    const handleScroll = () => {
        if (typeof window !== 'undefined') {
            const currentScrollY = window.scrollY;
            if (currentScrollY > lastScrollY.current && document.activeElement === searchInputRef.current) {
                setInputFocus(false);
                searchInputRef.current?.blur();
            }
            if (typeof hideHeaderOnScroll !== 'undefined') {
                hideHeaderOnScroll(currentScrollY);
            }
            lastScrollY.current = currentScrollY;
        }
    };

    useEffect(() => {
        // only hide suggestions and header in mobile
        if (typeof document !== 'undefined' && typeSearchBox === 'header' && ffIsHideMobileHeaderOnScrollEnabled) {
            document.addEventListener('scroll', handleScroll);
        }
        return () => {
            if (typeof document !== 'undefined') {
                document.removeEventListener('scroll', handleScroll);
            }
        };
    }, [typeSearchBox, ffIsHideMobileHeaderOnScrollEnabled]);

    useEffect(() => {
        if (inputFocus) {
            setInputFocus(false);
        }
    }, [location?.pathname]);

    useEffect(() => {
        // Add event listener when the component mounts
        document.addEventListener('mousedown', handleClickOutside);
        // Remove event listener when the component unmounts
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOnFocus = () => {
        setInputFocus(true);
    };

    const cleanValue = (v, maxLength = 100) => {
        // filter out control characters to help prevent XSS ...
        const cleanVal = v.replace(/[\x00-\x1F\x7F-\x9F]/g, ''); /* eslint no-control-regex: "off" */
        // ... and don't allow value to exceed maxLength
        return cleanVal.length <= maxLength ? cleanVal : cleanVal.substr(0, maxLength);
    };

    const cleanTerm = (input) => {
        // removes extra spaces an ., example input +_)(*()()_    roses . +_)()_)))() red +_)()_))

        const a = input.replace(/[`~+!@#$%^&*()_|=?;:'",.<>{}/\\]/g, ''); // replaces all special chars with spaces
        const b = a.replace(/\s+/g, ' '); // removes extra consecutive spaces with just one space
        const c = b.replace(/^\s+|\s+$/g, ''); // removes spaces in the beginning and end of search term
        // output would be "roses+red" for the url
        return c;
    };

    const concatSearchTerm = (url, value) => {
        let query = '';
        if (!includeSiblingBrandSearch) {
            query = `?facet=brand|${brand.id}`;
        }
        if (url.indexOf('searchTerm=') >= 0) {
            query += '';
        } else {
            // replaces spaces between words with _
            query += `${url.indexOf('?') >= 0 || query.indexOf('?') >= 0 ? '&' : '?'}searchTerm=${cleanTerm(value)?.trim().replace(/ /g, '_').toLowerCase()}`;
        }
        return `${url}${query}`;
    };

    const handleSearchValueChange = (event) => {
        setSearchValue(cleanValue(event.target.value));
        dispatch(getSearchResult(event.target.value));
        if (!event.target.value) {
            dispatch(clearSearchResult({}));
        }
    };

    const clearSearchValue = () => {
        setSearchValue('');
        setInputFocus(false);
    };

    const dispatchTrackEvent = (data) => {
        dispatch(trackEvent(data));
    };

    // emit custom tracking only if SF has campaign
    const dispatchTrackExperiment = (category = 'search button') => {
        if (salesforceResponse?.campaign) {
            dispatch(emitCustomTrackingEvent({
                action: 'Click',
                salesforceResponse,
                page: { type: pageType },
                eventSubType: 'campaign-tracking',
                label: includeSiblingBrandSearch ? 'All brand search' : 'Current brand search',
                category,
            }));
        }
    };

    const onHandleSubmit = (e) => {
        e?.preventDefault();
        if (searchValue) {
            dispatchTrackEvent({
                eventCategory: 'Search',
                eventAction: searchValue,
                eventLabel: 'MANUAL | <<pageType>>',
                searchVariety: 'Manual',
                searchPhrase: searchValue,
                eventName: 'search',
            });
            dispatchTrackExperiment();
            searchInputRef.current.blur();
            setSearchValue('');
            setInputFocus(false);
            dispatch(clearSearchResult({}));
            searchButtonClick();
            let search = '';
            if (!includeSiblingBrandSearch) {
                search = `?facet=brand|${brand.id}`;
            }
            const cleanSearchInput = cleanTerm(searchValue);
            let searchTermValue = cleanSearchInput
                .trim()
                .replace(/ /g, '+') // replaces spaces between words with +
                .toLowerCase();
            if (searchTermValue?.length !== 0) {
                if (brand?.['search-append-searchterm-url-param']) {
                    searchTermValue = concatSearchTerm(searchTermValue, cleanSearchInput);
                }
                history.push({
                    pathname: `/searchterm/${searchTermValue}`,
                    search,
                    state: { searchInputTerm: cleanSearchInput || '' },
                });
            }
        }
    };

    const clearSearch = (persistValue = false, queryString = null) => {
        if (!persistValue) {
            clearSearchValue();
        } else if (queryString) {
            clearSearchValue();
            dispatchTrackExperiment('suggestion');
            dispatchTrackEvent({
                eventCategory: 'Search',
                eventLabel: queryString,
                eventAction: 'SUGGESTED | <<pageType>>',
                searchVariety: 'Suggested',
                searchPhrase: searchValue,
                eventName: 'search',
            });
        }
        searchButtonClick();
    };

    return (
        <div ref={searchContainerRef} className={`${classes.root} ${inputFocus ? classes.rootShadow : ''}`}>
            <form onSubmit={onHandleSubmit}>
                <Paper elevation={0} component="div" className={classes.paperRoot}>
                    <SearchIcon />
                    {!searchValue && (
                        <span className={classes.customPlaceHolder}>
                            {placeHolder}
                        </span>
                    )}
                    <InputBase
                        inputRef={searchInputRef}
                        className={classes.inputBase}
                        id={`SearchBox_${typeSearchBox}`}
                        name={`searchTerm_${typeSearchBox}`}
                        onFocus={handleOnFocus}
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        autoComplete="off"
                        type="text"
                        inputProps={{
                            'aria-label': 'search box',
                            'data-test': 'header-search-box-v2', // do NOT change or remove data-test attribute
                            'data-testid': 'header-search-box-v2',
                        }}
                    />
                    {Boolean(searchValue) && (
                        <IconButton onClick={clearSearchValue} aria-label="clear search" className={classes.clearSearchButton} size="small">
                            <CloseIcon className={classes.clearSearchIcon} />
                        </IconButton>
                    )}
                </Paper>
                {inputFocus
                    ? (
                        <ElasticSearchSuggestions
                            brand={brand.domain}
                            phrase={cleanTerm(searchValue)}
                            concatSearchTerm={concatSearchTerm}
                            clearSearch={clearSearch}
                            cleanTerm={cleanTerm}
                            targeting={targeting}
                            experimentsService={experimentService}
                            targetingEnabled={isGraphqlTargetingEnabled}
                            track={dispatchTrackEvent}
                            trackExperiment={dispatchTrackExperiment}
                            isSearchBarBrandFilterEnabled
                            includeSiblingBrandSearch={includeSiblingBrandSearch}
                            setIncludeSiblingBrandSearch={setIncludeSiblingBrandSearch}
                            handleSearchSubmit={onHandleSubmit}
                        />
                    )
                    : <></>}
            </form>
        </div>
    );
}

SearchV2.propTypes = {
    typeSearchBox: PropTypes.string,
    brand: PropTypes.object.isRequired,
    hideHeaderOnScroll: PropTypes.func,
    ffIsHideMobileHeaderOnScrollEnabled: PropTypes.bool,
    salesforceResponse: PropTypes.object,
    searchButtonClick: PropTypes.func,
};

SearchV2.defaultProps = {
    typeSearchBox: null,
    hideHeaderOnScroll: () => {},
    salesforceResponse: {},
    ffIsHideMobileHeaderOnScrollEnabled: false,
    searchButtonClick: () => {},
};

export default SearchV2;
